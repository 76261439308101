.resource-topic-button-container {
  width: 300px;

  overflow: hidden;
  border-radius: 15px;
  background-color: #e6e3e3;
  box-shadow: 8px 8px 20px 0.01em gray;
  cursor: pointer;
}

.resource-topic-button-title {
  color: white;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  padding: 10px;
  min-height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: gray;
  text-align: center;
}

.resource-topic-button-gray {
  display: flex;
  height: 68px;
  background-color: #d6e2f2;
}
