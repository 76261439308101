html,
body,
#app,
#root,
#app > div {
  height: 100%;
}

.app {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-top: 20px solid #70a1c0;
  border-right: 30px solid #70a1c0;
  border-left: 30px solid #70a1c0;
  border-bottom: 15px solid #70a1c0;
}

.app-content-body-bar-left {
  position: absolute;
  background-color: #0069a8;
  height: 50px;
  width: 30px;
  left: -30px;
  top: -20px;
}

.app-content-body-bar-right {
  position: absolute;
  background-color: #0069a8;
  height: 50px;
  width: 30px;
  right: -30px;
  top: -20px;
}

.app-content-body-bar-outer {
  position: absolute;
  width: 100%;
  height: 30px;
  background-color: #0069a8;
  left: -10px;
  top: -30px;
  padding-right: 20px;
}

.app-content-body-bar-inner {
  width: 100%;
  height: 10px;
  background-color: #164362;
}

.main-app-container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  min-width: 1000px;
}

.title-text {
  font-family: Playfair Display, serif;
  color: white;
  font-size: 4.5em;
  width: 780px;
  line-height: 1;
  z-index: 2;
}

.title-start-button {
  width: 400px;
  margin-top: 30px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #0069a8;
  border-radius: 10px;
  padding: 20px;
  filter: drop-shadow(2px 4px 6px black);
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
  z-index: 3;
}

.main-container {
  position: relative;
  flex-direction: column;
  display: flex;
}

.main-spacer {
  position: relative;
  display: flex;
  height: 300px;
}

.main-content-body {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 10px solid #c8d3e5;
  background-color: white;
}

.main-content-body-bar-left {
  position: absolute;
  background-color: #0069a8;
  height: 10px;
  width: 10px;
  left: -10px;
  top: 0;
}

.main-content-body-bar-right {
  position: absolute;
  background-color: #0069a8;
  height: 10px;
  width: 10px;
  right: -10px;
  top: 0;
}

.main-content-body-bar-outer {
  position: absolute;
  width: 100%;
  height: 10px;
  background-color: #0069a8;
  left: -10px;
  top: -10px;
  padding-right: 20px;
}

.main-content-body-bar-inner {
  width: 100%;
  height: 10px;
  background-color: #053256;
}

.main-collab-title {
  display: flex;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  font-size: 25px;
  color: #003250;
}

.main-collab-logos-top {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.main-collab-logos-bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #e1edf4;
}

.main-collab-big-logo {
  padding: 10px;
}

.main-collab-logos-bottom-row {
  display: flex;

  width: 90%;
  padding: 20px;
  padding-bottom: 50px;
  flex-direction: row;
  justify-content: space-around;
}

.main-collab-logos-bottom-row img {
  height: 115px;
}

.main-footer {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.main-footer-top {
  height: 20px;
  width: 100%;
  background-color: #cb9822;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.main-footer-center {
  background-color: white;

  width: 100%;
}

.main-footer-buttons {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  padding: 50px;
}

.main-footer-bottom {
  height: 20px;
  background-color: #164362;
  width: 100%;
}
