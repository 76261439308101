.resource-lib-container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #e0edf4;
  align-items: center;
  width: 100%;
  height: 100%;
}

.resource-lib-container-centered {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
