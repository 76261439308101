.resource-filter-main-container {
}
.resource-filter-container {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
  align-items: center;
}

.resource-filter-main-container-title {
  font-size: 30px;
  font-weight: bold;
  color: #355c73;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 30px;
}

.resource-filter-main-container-subtitle {
  font-size: 20px;
  font-weight: bold;
  color: #2d85c4;
  margin-bottom: 20px;
  margin-top: 30px;
}

.resource-filter-selector-label {
  font-size: 18px;
  margin-bottom: 5px;
  color: #355c73;
}

.resource-filter-or {
  margin-left: 20px;
  margin-right: 20px;
}

.resource-filter-selector {
  display: flex;
  flex-direction: column;
}

.resource-filter-select {
  color: #355c73;
  font-size: 15px;
  font-weight: bold;
  font-family: "Open Sans";
  padding: 10px;
}
