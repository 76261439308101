.resource-results-container {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 100%;

  align-items: center;
}

.resource-results-grid {
  margin-top: 50px;
  max-width: 1200px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}

.result-document {
  position: relative;
  height: auto;
  border: 1px solid gray;
  padding: 10px;
  position: relative;
  cursor: pointer;
}
