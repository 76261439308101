.answer-section-button-container {
  padding: 10px;
  background-color: #af78e3;
  max-width: 60%;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-top: 20px;
}
