.answers-container {
  height: 100%;
  background-color: white;
  padding: 50px;
}

li {
  padding-top: 10px;
}

.answers-intro {
  font-weight: bold;
}
.answer-page-buttons-container {
  position: relative;
  display: flex;
  background-color: white;
  justify-content: flex-end;
  padding: 10px;
}
