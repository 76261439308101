.header-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  background-color: white;
  border-top: 20px solid #164362;
  border-bottom: 20px solid #cb9822;
}

.logo-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  cursor: pointer;
}

.logo {
  z-index: 2;
  height: 236px;
}

.buttons-container {
  color: white;
  font-size: 25px;
  font-weight: bold;
  background-color: #0069a8;
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 300px;
  justify-content: space-around;
  align-items: flex-end;
}

.buttons {
  padding-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 75%;
  cursor: pointer;
}

.slanted-div {
  width: 2px;
  height: 40px;
  background-color: white;
  transform: skewX(-50deg);
  transform-origin: top left;
}
