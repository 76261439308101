.container {
  position: relative;
  height: 200px;
  width: 400px;
  border-radius: 20px;
  background-color: #d0e0ea;
  cursor: pointer;
}

.container img {
  position: absolute;
  left: -30px;
  top: 50px;
}

.text {
  display: flex;
  flex-direction: column;

  padding: 10px;
}

.title {
  font-weight: bolder;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 25px;
}
.body {
  margin-left: 50px;
  font-weight: bold;
}
