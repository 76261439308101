.questions-container {
  height: 100%;
  background-color: white;
  border-radius: 10px;
  padding: 50px;
}

.question-submit-button {
  padding: 20px;
  background-color: cornflowerblue;
  color: white;
  font-weight: bold;
  font-family: "Open Sans" sans-serif;
  border: 2px solid gray;
  font-size: 20px;
  margin-top: 20px;
  cursor: pointer;
}
.question-submit-button:hover {
  background-color: rgb(120, 156, 227);
}
.question-submit-button a {
  color: white;
}
.question-container {
  margin-bottom: 20px;
}

.question {
  font-family: Playfair Display, serif;
  color: black;
  font-size: 30px;
}
.q1-radio-option {
  padding: 5px;
  font-family: "Open Sans", sans-serif;
}

.q1-radio-option input {
  margin-right: 10px;
}

.question-1-answers {
  margin-top: 10px;
}

.question-3-column-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 15px;
}
.question-3-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.question-3-answers {
  padding-right: 20px;
}
.question-3-value {
  margin-top: 10px;
}
.question-3-colum-title {
  padding: 15px;
  font-weight: bold;
  border-right: 1px solid white;
  text-align: center;
  margin-bottom: 10px;
  color: white;
}

. .question-3-answers-container {
  margin-top: 20px;
  font-family: "Open Sans", sans-serif;
}

.question-3-answers-container th {
  color: white;
  padding: 10px;
}

.question-3-answers-container td {
  padding: 5px;
}

.accent-img-1 {
  position: absolute;
  right: 0px;
  border-radius: 20px;
  margin-right: 50px;
}

.question-4-title {
  background-color: #af78e3;
  color: white;
  font-weight: bold;
  font-size: 20px;
  padding: 5px;
}
.question-4-container {
  margin-bottom: 20px;
  width: fit-content;
  block-size: fit-content;
}
.question-container {
  font-family: "Open Sans", sans-serif;
}

.question-4-answers {
  font-family: "Open Sans", sans-serif;
}

.question-4-block-container {
  margin-bottom: 20px;
}

.question-4-answer {
  margin-top: 10px;
}

.question-4-answer input {
  margin-right: 10px;
}
